<template src="./Events.html"></template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { SwiperSlide } from "vue-awesome-swiper";
import eventsService from "@/services/Events";
import ArrobaMedellinHorizontalCard from "@/components/ArrobaMedellinHorizontalCard/ArrobaMedellinHorizontalCard";
import ArrobaMedellinSquareCard from "@/components/ArrobaMedellinSquareCard/ArrobaMedellinSquareCard";
import ArrobaMedellinSeeker from '@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker';
import ArrobaMedellinArrobitaNoData from '@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData';
import EventsInformationModal from './EventsInformationModal/EventsInformationModal';
import EnterTheEventModal from './EnterTheEventModal/EnterTheEventModal';
import NewEventModal from './NewEventModal/NewEventModal';
import EditEventModal from './EditEventModal/EditEventModal';
import DeleteEventModal from './DeleteEventModal/DeleteEventModal';

import swiperSmallOption from "@/utils/swiper-small-option";
import swiperLargeOption from "@/utils/swiper-large-option";

export default {
  name: "Events",
  components: {
    SwiperSlide,
    ArrobaMedellinHorizontalCard,
    ArrobaMedellinSquareCard,
    ArrobaMedellinSeeker,
    EventsInformationModal,
    EnterTheEventModal,
    NewEventModal,
    DeleteEventModal,
    ArrobaMedellinArrobitaNoData,
    EditEventModal
  },
  data() {
    return {
      typeCard1: { button: "button--green", size: "small" },
      typeCard2: { button: "button--green" },
      typeCard: { button: "button--green", size: "small" },
      swiperSmallOption: swiperSmallOption,
      swiperLargeOption: swiperLargeOption,
      openInformationModal: false,
      openEnterTheEventModal: false,
      openNewEventModal: false,
      openEventModifyModal: false,
      openDeleteEventModal: false,
      eventToModifiy: null,
      eventToDelete: null,
      codeEventToDelete: null,
      eventInformation: {},
      enterTheEventImage: '',
      allEvents: null,
      todayEvents: null,
      attendedEvents: null,
      lastEvents: null,
      interestingEvents: null,
      myEvents: null,
      searchedEvents: null,
      errorMessage: false,
      userInfo: null,
      role: null,
      itemsToShowSliderTop: Number(1),
      itemsToShowSliderMiddle: Number(1),
      itemsToShowSliderBottom: Number(1),
    };
  },
  async beforeCreate () {
    try {
      this.allEvents =  await eventsService.getAllEvent();
      this.todayEvents = await eventsService.getTodayEvent();
      this.attendedEvents = await eventsService.getAttendedEvent();
      this.lastEvents = await eventsService.getLastEvent();
      const interestingEventsResponse = await eventsService.getInterestingEvent();
      this.interestingEvents = interestingEventsResponse;
      this.searchedEvents = interestingEventsResponse;
      this.myEvents = await eventsService.getMyEvents();
    } catch (error) {
      console.error(error);
    }
  },
  created() {
    const user = this.$store.getters.getUser;
    this.role = user.tipo_rol;
  },
  mounted() {
    window.addEventListener('resize', this.reportWindowSize);
    this.reportWindowSize();
  },
  methods: {
    openEventInformationModal (obj) {
      this.eventInformation = obj;
      this.openInformationModal = true;
    },
    openTheEnterTheEventModal (img) {
      this.enterTheEventImage = img;
      this.openEnterTheEventModal = true;
    },
    openTheNewEventModal () {
      this.openNewEventModal = true;
    },
    openEventModify (event) {
      const idCategory = event.idCategory.toString();
      const date = moment(event.eventDate).toDate();
      const initialHour = moment(event.startHour).toDate();
      const finalHour = moment(event.finishHour).toDate();
      this.eventToModifiy = {...event, idCategory, date, initialHour, finalHour};
      this.openEventModifyModal = true;
      this.openInformationModal = false;
    },
    openModalDeleteEvent (id, code) {
      this.eventToDelete = id;
      this.codeEventToDelete = code;
      this.openDeleteEventModal = true;
      this.openInformationModal = false;
    },
    closeInformationModal () {
      this.openInformationModal = false;
    },
    closeEnterTheEventModal () {
      this.openEnterTheEventModal = false;
    },
    closeNewEventModal () {
      this.openNewEventModal = false;
    },
    closeEventModify () {
      this.openEventModifyModal = false;
      this.eventToModifiy = null;
    },
    closeDeletEventModal () {
      this.eventToDelete = null;
      this.codeEventToDelete = null;
      this.openDeleteEventModal = false;
    },
    async eventCreated () {
      try {
        const allEventsResponse = await eventsService.getAllEvent();
        this.allEvents = allEventsResponse.data;
      } catch (error) {
        console.error(error);
      }
    },
    searchEvents (searchWord) {
      eventsService.getSearchEvents(searchWord)
        .then(response => {
          if (response.length < 1) {
            this.errorMessage = true;
          } else {
            this.searchedEvents = response;
            this.errorMessage = false;
          }
        })
        .catch(error => console.error(error))
    },
    redirectTo(route) {
      this.$router.push({path: `${route}`, query: { type: 'interest', district: 'events' }})
    },
    reportWindowSize () {
      if (window.innerWidth < 550){
        this.itemsToShowSliderTop = Number(1);
        this.itemsToShowSliderMiddle = Number(1);
        this.itemsToShowSliderBottom = Number(1);
      }
      else if (window.innerWidth < 1224){
        this.itemsToShowSliderTop = Number(2);
        this.itemsToShowSliderMiddle = Number(2);
        this.itemsToShowSliderBottom = Number(2);
      }
      else if (window.innerWidth < 1580){
        this.itemsToShowSliderTop = Number(2);
        this.itemsToShowSliderMiddle = Number(2);
        this.itemsToShowSliderBottom = Number(3);
      }
      else if (window.innerWidth > 1580){
        this.itemsToShowSliderTop = Number(3);
        this.itemsToShowSliderMiddle = Number(2);
        this.itemsToShowSliderBottom = Number(4);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    openModalNewEvent() {
      return this.openNewEventModal
    },
    openModalDelet() {
      return this.openDeleteEventModal
    },
    openModalEditEvent() {
      return this.openEventModifyModal
    },
    openModalEnterToEvent() {
      return this.openEnterTheEventModal;
    },
    openModalInformation() {
      return this.openInformationModal;
    },
    roleValidation() {
      return ['Student', 'Teacher', 'Entrepreneur'].some(a => a === this.role);
    }
  },
    watch: {
      user: function(value) {
        this.userInfo = value;
      }
  }
};
</script>

<style lang="scss" scoped src="./Events.scss"></style>
