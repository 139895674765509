<template src="./EventsInformationModal.html">
</template>

<script>
import moment from 'moment';
export default {
  name: 'events-informtion-modal',
  props: {
    openInformationModal: { type: Boolean, defaul: false },
    role: { type: String, default: 'Student' },
    event: { type: Object, default: () => {
      return {}
    }}
  },
  methods: {
    closeModal() {
      this.$emit('closeInformationModal')
    },
    redirectTo(url) {
      window.open(url, '_blank');
    },
    openEventModify() {
      this.$emit('openEventModify', this.event)
      this.closeModal()
    },
    openDeleteEvent() {
      this.$emit('openEventDelete', this.event.idEvent, this.event.code)
      this.closeModal()
    }
  },
  computed: {
    closeInscriptionDate() {
      const date = moment(this.event.date).subtract(1, 'd');
      const hour = moment(this.event.initialHour);
      return `${date.format('ddd-MM-YYYY')} ${hour.format('hh:mm')}`;
    },
    roleValidation() {
      return ['Student', 'Teacher', 'Entrepreneur'].some(a => a === this.role);
    },
    imgUrl() {
      return this.event.imagen ? this.event.imagen : "https://storage.googleapis.com/arrobamedellin.appspot.com/Img_default.png";
    }
  }
}
</script>

<style lang="scss" scoped src="./EventsInformationModal.scss">
</style>