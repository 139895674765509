<template src="./NewEventModal.html">
</template>

<script>
import eventsService from "@/services/Events";
import categoryService from "@/services/Category";
import debounce from 'lodash/debounce';

export default {
  name: "new-event-modal",
  data () {
    return {
      categoriesList: null,
      eventName: null,
      category: null,
      moderator: null,
      moderatorSearchExpression: '',
      moderatorSearch: [],
      eventDate: null,
      startHour: null,
      finishHour: null,
      videoURL: null,
      description: null,
      capacity: null,
      imagen: null,
    }
  },
  props: {
    openNewEventModal: { type: Boolean, defaul: false }
  },
  async beforeCreate () {
    try {
      this.categoriesList = await categoryService.getCategoriesWhitSubcategory()
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeNewEventModal')
    },
    formatter (d) {
      return d.toLocaleDateString()
    },
    createEvent() {
      const obj = {
        name: this.eventName,
        description: this.description,
        idCategory: parseInt(this.category.id),
        moderator: this.moderator.arroba_id,
        date: this.eventDate,
        initialHour: this.startHour,
        finalHour: this.finishHour,
        aforo: parseInt(this.capacity, 10),
        videoURL: this.videoURL,
        imagen: this.category.imagen
      }
      eventsService.newEvent(obj)
        .then(() => {
          this.$buefy.toast.open({
            duration: 5000,
            position: "is-bottom",
            message: "Se creó el evento con éxito",
            type: "is-success"
          });
          this.closeModal()
        })
        .catch(error => {
          this.$buefy.toast.open({
            duration: 5000,
            position: "is-bottom",
            message: "Falló la creación del evento.",
            type: "is-error"
          });
          console.error(error)
        })
        .finally(() => this.$emit('eventCreated'))
    },
    searchExpression: debounce(function(name) {
      if (!name.length) {
        this.moderatorSearch = null
        return
      }
      setTimeout(() => {
          eventsService.getModerators(this.moderatorSearchExpression)
            .then(response => {
              this.moderatorSearch = [];
              response.forEach((item) => this.moderatorSearch.push(item))
            })
            .catch(error => {
              this.moderatorSearch = []
              console.error(error)
            })
        })
      }, 300)
  },
  computed: {
    disableButton () {
      const { eventName, category, moderator, eventDate, startHour, finishHour, videoURL, description, capacity } = this;
      return eventName && category && moderator && eventDate && startHour && finishHour && videoURL && description && capacity;
    }
  }
}
</script>

<style lang="scss" scoped src="./NewEventModal.scss">
</style>
