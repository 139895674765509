<template src='./ArrobaMedellinSquareCard.html'></template>

<script>
export default {
  name: 'arroba-medellin-square-card',

  props: {
    img: { type: String },
    imgDescription: { type: String, default: "Imagen" },
    title: { type: String },
    subtitle: { type: String },
    body: { type: [String, Object], required: false },
    typeCard: { type: Object },
    buttonText: { type: String },
    url: { type: Number},
  },
  methods: {
    pushButton() {
      this.$emit('verticalCardClickButton')
    }
  },
  computed: {
    disableButton() {
      return this.url >= 20;
    },
    imgUrl() {
      return this.img ? this.img : 'https://storage.googleapis.com/arrobamedellin.appspot.com/Img_default.png'
    }
  }
}
</script>

<style lang="scss" scoped src="./ArrobaMedellinSquareCard.scss"></style>
